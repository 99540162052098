import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export const HeadingOne = styled.h1`
  color: var(--theme-color-title);
  font-family: var(--theme-typography-primary-font);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.38;
  margin: 0 0 15px;
  grid-column: 2 / 3;

  &.mod-no-margin {
    margin: 0;
  }

  &.mod-extra-margin {
    margin: 0 0 var(--spacer);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 1 / 9;
      font-size: 34px;
    `,
  )}
`;
